<template>
    <div class="sidebar-detail" :class="{'sidebar-detail--is-expanded': isExpanded}">
        <div class="sidebar-detail__header" >
            <div class="p-2 d-flex align-items-center">
                    <mercur-button class="btn btn-icon" :to="parentRoute">
                        <i class="fas fa-times"></i>
                    </mercur-button>
                    <span class="ml-2">{{orderName}}</span>

            </div>
        </div>
        <div class="sidebar-detail__content">
            <div v-if="loading" class="sidebar-detail__spinner" >
                <mercur-spinner
                    :size="2"
                ></mercur-spinner>
            </div>
            <div v-else>
                <alert type="info" class="mt-10" v-if="orderlineDetails && !!orderlineDetails.reprintedFromOrderLineId">
                    This is a reprint!
                </alert>
                <dl v-for="(value, key) in orderlineDetailsTable" :key="key">
                    <dt>{{key | beautify}}:</dt>
                    <dd v-if="value === null || value === '' || typeof value === 'undefined'">-</dd>
                    <dd v-else-if="key === 'Product Cost'">{{value | asMoney(getCurrencyCodeForOrderLine(orderlineDetails))}}</dd>
                    <dd v-else-if="key === 'Dispatch Date'">{{value | asDate}}</dd>
                    <dd v-else-if="key === 'Target Dispatch Date'">{{value | asDate}}</dd>
                    <dd v-else-if="key === 'dateUpdated'">{{value | asDatetime}}</dd>
                    <dd v-else-if="key === 'Tracking URL'"><a :href="value" target="_blank">{{value}}</a></dd>
                    <div v-else-if="key === 'Product attributes'" v-for="(attribute, key) in value" :key="key">
                        <dd><span class="font-weight-normal">{{attribute.attributeName}}:</span> {{attribute.attributeOption}}</dd>
                    </div>
                    <div v-else-if="key === 'Destination'">
                        <dd><span class="font-weight-normal">address:</span> {{value.address}}</dd>
                        <dd><span class="font-weight-normal">house number:</span> {{value.houseNumber}}</dd>
                        <dd><span v-if="value.houseNumberAdditional" class="font-weight-normal">house aditional number:</span> {{value.houseNumberAdditional}}</dd>
                        <dd><span class="font-weight-normal">zip code:</span> {{value.zipCode}}</dd>
                        <dd><span class="font-weight-normal">city:</span> {{value.city}}</dd>
                        <dd><span class="font-weight-normal">country:</span> {{value.country}}</dd>
                    </div>
                    <div class="text-center" v-else-if="key === 'Logs'">
                        <mercur-button class="btn btn-sm btn-raised mt-2" @click="openLogs">
                            <span class="mx-2">View Event Logs</span>
                            <mercur-chip class="ml-3 " v-text="value.length"></mercur-chip>
                        </mercur-button>
                    </div>
                    <dd v-else>{{value}}</dd>
                </dl>
            </div>
        </div>
        <mercur-dialog :is-open.sync="logDialogIsOpen">
            <h3>Event Logs</h3>
            <div class="mb-3" v-for="(log, key) in logs" :key="key">
                <span class="font-weight-normal event-time"><i class="fas fa-clock mr-2 text-grey" />{{log.createdAt}}</span><br>
                <span class="font-weight-normal">{{log.eventName}}</span>
            </div>
            <div class="text-right">
                <mercur-button class="btn btn-primary" @click="logDialogIsOpen = false">Close</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import Alert from '@/components/Alert'
import { debounce } from 'debounce'

export default {
    name: 'OrderLineDetail',
    components: { Alert },
    data () {
        return {
            orderlineDetails: null,
            isExpanded: false,
            componentIsBeingDestroyed: false,
            loading: false,
            logDialogIsOpen: false,
            logs: [],
        }
    },
    props: {
        selectedSupplierId: {
            default: null,
        },
        filterStatus: {
            default: null,
        },
        isVirtualSupplierOrderlineOverview: {
            required: false,
            default: false,
        },
    },
    computed: {
        breadcrumbElement () {
            return {
                name: this.orderName || '...',
            }
        },
        orderName () {
            if (!this.orderlineDetails) {
                return null
            }
            return this.orderlineDetails.orderLineNumber
        },
        orderlineDetailsTable () {
            if (!this.orderlineDetails) {
                return null
            }

            return {
                'Order Number': `${this.orderlineDetails.orderNumber} - ${this.orderlineDetails.orderLineNumber}${this.orderlineDetails.quote ? ' (quote)' : ''}`,
                'Order Status': this.$options.filters.beautify(this.orderlineDetails.orderLineStatus),
                'SKU': this.orderlineDetails.supplierDescription,
                'Producer SKU': this.orderlineDetails.supplierProducerSku,
                'Cutoff Time': this.orderlineDetails.supplierProducer.supplierCutOffTime || this.orderlineDetails.supplierProducer.cutOffTime,
                'Producer reference': this.orderlineDetails.supplierProducerReference,
                'Product attributes': this.orderlineDetails.supplierAttributes,
                'Item reference': this.orderlineDetails.itemReference,
                'Quantity': this.orderlineDetails.supplierQuantity,
                'Product Cost': this.orderlineDetails.supplierProductCost,
                'Packs': this.orderlineDetails.packages,
                'Destination': this.orderlineDetails.address,
                'Target Dispatch Date': this.orderlineDetails.targetDispatchDate,
                'Carrier Service Level': this.orderlineDetails.supplierCarrier.carrierName,
                'Shipment reference': this.orderlineDetails.shipmentReference,
                'Harmonization Code': this.orderlineDetails.supplierProductHarmonizationCode,
                'Tracking URL': this.orderlineDetails.trackingUrl,
                'Logs': this.orderlineDetails.events,
            }
        },
        parentRoute () {
            if (this.isVirtualSupplierOrderlineOverview) {
                return {
                    name: 'OrderListOverview',
                    params: {
                        orderLineOverviewType: this.$route.params.orderLineOverviewType,
                    },
                }
            }
            const route = {
                name: 'SupplierOrderlines',
                params: {
                    status: this.filterStatus.value,
                },
            }
            if (!this.isSupplier) {
                route.params.supplierId = this.supplierId
            }

            return route
        },

        url () {
            return CONFIG.API.ROUTES.ORDERS.ORDERLINES.GET.replace('{orderId}', this.$route.params.orderId).replace('{orderLineId}', this.$route.params.orderLineId)
        },
    },
    methods: {
        getCurrencyCodeForOrderLine (orderLine) {
            return this.$store.getters['suppliers/getCurrencyCodeForOrderLine'](orderLine)
        },
        getOrderLineDetails (params) {
            this.orderlineDetails = null

            if ((params && (!params.orderLineId || !params.supplierId)) || this.componentIsBeingDestroyed) {
                return
            }

            const payload = {
                supplierId: this.selectedSupplierId || this.supplierId,
                orderLineStatus: this.filterStatus.value,
            }

            this.loading = true
            this.addJob(this.url)
            this.$api.post(this.url, payload).then(({ data }) => {
                this.orderlineDetails = data.data
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting order details failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(this.url)
            })
        },
        openLogs () {
            this.logs = this.orderlineDetails.events
            this.logDialogIsOpen = true
        },
    },
    created () {
        this.orderLineDetailParamsWatcher = this.$watch(() => {
            return {
                orderLineId: this.$route.params.orderLineId,
                supplierId: this.selectedSupplierId || this.supplierId,
            }
        }, debounce(this.getOrderLineDetails, 100), {
            deep: true,
            immediate: true,
        })
        this.$root.$on('order-line-updated', () => {
            this.getOrderLineDetails()
        })
    },
    beforeDestroy () {
        this.componentIsBeingDestroyed = true
        this.finishJob(this.url)
    },
}
</script>
<style scoped>
.event-time {
    font-size: 0.8rem;
}
</style>
